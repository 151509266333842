// Generated by Framer (28b2919)

import { addFonts, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {X_lmFzwVW: {hover: true}};

const cycleOrder = ["X_lmFzwVW"];

const variantClassNames = {X_lmFzwVW: "framer-v-1mm5us8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "X_lmFzwVW", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6ntFs", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1mm5us8", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"X_lmFzwVW"} ref={ref} style={{"--border-bottom-width": "0.1px", "--border-color": "rgb(34, 32, 32)", "--border-left-width": "0.1px", "--border-right-width": "0.1px", "--border-style": "solid", "--border-top-width": "0.1px", backgroundColor: "rgb(26, 26, 26)", ...style}} variants={{"X_lmFzwVW-hover": {"--border-bottom-width": "0px", "--border-left-width": "0px", "--border-right-width": "0px", "--border-top-width": "0px", backgroundColor: "rgb(39, 38, 38)"}}} {...addPropertyOverrides({"X_lmFzwVW-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6ntFs [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6ntFs .framer-5igbiw { display: block; }", ".framer-6ntFs .framer-1mm5us8 { height: 74px; position: relative; width: 64px; }", ".framer-6ntFs .framer-v-1mm5us8 .framer-1mm5us8 { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 74
 * @framerIntrinsicWidth 64
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"rRwq9CrGb":{"layout":["fixed","fixed"]}}}
 */
const FramerqsRnVfJSx: React.ComponentType<Props> = withCSS(Component, css, "framer-6ntFs") as typeof Component;
export default FramerqsRnVfJSx;

FramerqsRnVfJSx.displayName = "tile";

FramerqsRnVfJSx.defaultProps = {height: 74, width: 64};

addFonts(FramerqsRnVfJSx, [])